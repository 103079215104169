<template>
    <div class="CooperativeItem">
        <div class="itemBox">
            <div class="breadcrumb">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: 'firmInfo' }">合作企业</el-breadcrumb-item>
                    <el-breadcrumb-item>人才培养列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="Detail">
                <CardHeader :title="'人才培养方案'" :pt="0" :pb="24" />
                <div class="itemLists" v-if="InvestmentPageList.length>0">
                    <ItemListCard :Path="'itemListInfo'" :data="InvestmentPageList" :apiPath="'enterprisePersonnelTraining'" type="calendar"></ItemListCard>
                </div>
                <div v-else class="nodata">
                    <el-image style="width: 240px; height: 180px" :src="require('@/assets/images/nodata.png')" />
                    <span class="mt-18">暂无数据</span>
                </div>
                <div class="loading" v-if="totalCount > 10">
                    <Pagination :total="totalCount" :limit="10" :layout="'prev, pager, next'" :autoScroll="false" @pagination="areaPagination" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination";
    import ItemListCard from "@/components/Common/ItemListCard.vue";
    import CardHeader from "@/components/Common/CardHeader.vue";
    import { dateTimeFilter } from "@/filters";
    import { getPageList as getPersonnePageList } from "@/api/enterprise/enterprisePersonnelTraining";
    export default {
        name: "EnterprisePersonnelTraining",
        props: {},
        components: {
            CardHeader,
            ItemListCard,
            Pagination
        },
        data() {
            return {
                loading: false,
                totalCount: 0,
                InvestmentPageList: [],
                queryData: {
                    EnterpriseId: this.$route.query.id,
                    PageSize: 10,
                    PageIndex: 1
                },
                Resume: {}
            };
        },
        computed: {
            formartDate() {
                return (data, formate) => dateTimeFilter(data, formate);
            }
        },
        watch: {},
        methods: {
            //
            async getPageList() {
                this.loading = true;
                try {
                    const { Result } = await getPersonnePageList(this.queryData);
                    this.InvestmentPageList = Result.Data;
                    this.totalCount = Result.TotalCount;
                    // console.log(111, Result);
                    // console.log(112, this.queryData);
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            areaPagination(val) {
                this.queryData.PageIndex = val.page;
                this.getPageList();
            }
        },
        created() {
            this.getPageList();
        },
        mounted() {}
    };
</script>

<style scoped lang="scss">
    .CooperativeItem {
        width: 100%;
        min-height: calc(100vh - 202px);
        background: #f5f5f5;
    }

    .itemBox {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding-bottom: 40px;

        /deep/ .el-breadcrumb__item:nth-child(2) {
            color: #111;
        }

        /deep/ .el-breadcrumb__inner {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 20px;
        }

        .breadcrumb {
            box-sizing: border-box;
            width: 100%;
            height: 46px;
            padding: 16px 0 8px;
        }

        .Detail {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 32px;
            min-height: 66vh;
            background: #ffffff;
            border-radius: 8px;
            overflow: hidden;

            .itemLists {
                min-height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;

                .noData {
                    color: #3d5afe;
                }
            }
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        text-align: center;

        /deep/ .pagination-container {
            background: #f5f5f5;
        }
    }

    .nodata {
        flex: 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-sizing: border-box;

        span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }
    }
</style>